.bg-gradient-custom {
  background: linear-gradient(126deg, #00072E 0%, #00147D 100%);
}


@keyframes slide-right {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(260px);
  }
}


@keyframes slide-up {
  0% {
    transform: translateY(500%);
    opacity: 0;
  }

  50% {
    transform: translateY(250%);
    opacity: 0.5;
    /* More faded out at this point */
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }

  45% {
    opacity: 0;
  }

  55% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.image-container {
  position: relative;
  width: 100%;
  min-height: 50vh;
  /* Default for small screens */
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: fadeInOut 3s ease-in-out infinite;
}

.image-container img:nth-child(1) {
  animation-delay: 0s;
}

.image-container img:nth-child(2) {
  animation-delay: 4s;
}

.image-container img:nth-child(3) {
  animation-delay: 8s;
}

.image-container img:nth-child(4) {
  animation-delay: 12s;
}

/* Responsive adjustments */
@media only screen and (min-width: 600px) {
  .image-container {
    min-height: 60vh;
  }
}

@media only screen and (min-width: 768px) {
  .image-container {
    min-height: 80vh;
  }

  .image-container img {
    animation: fadeInOut 3s ease-in-out infinite;
  }
}

@media only screen and (min-width: 992px) {
  .image-container {
    min-height: 100vh;
  }

  .image-container img {
    animation: fadeInOut 3s ease-in-out infinite;
  }
}

@media only screen and (min-width: 1200px) {
  .image-container {
    min-height: 130vh;
  }

  .image-container img {
    height: 130vh;
    animation: fadeInOut 3s ease-in-out infinite;
  }
}

.gradient-text {
  display: inline-block;
  background: linear-gradient(88.58deg, #C62B6D 20%, #973B87 40%, #6F489C 70%, #3E59B7 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  animation: gradientChange 5s ease-in-out infinite;
}

@keyframes gradientChange2 {
  0% {
    background-position: 100% 50%;
    -webkit-text-fill-color: transparent;
  }

  50% {
    background-position: 50% 50%;
    -webkit-text-fill-color: transparent;
  }

  100% {
    background-position: 0% 50%;
    -webkit-text-fill-color: transparent;
  }
}

.gradient-text2 {
  display: inline-block;
  background: linear-gradient(90deg, #ffffff, #ffffff, #ffffff, #FFA5CB, #FFA5CB, #FFA5CB, #8397FF, #FFA5CB, #FFA5CB, #ffffff, #ffffff, #ffffff);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientChange2 5s ease-in-out infinite;
}



@keyframes gradientChange3 {
  0% {
    background-position: 100% 50%;
    -webkit-text-fill-color: transparent;
  }

  50% {
    background-position: 50% 50%;
    -webkit-text-fill-color: transparent;
  }

  100% {
    background-position: 0% 50%;
    -webkit-text-fill-color: transparent;
  }
}

.gradient-text3 {
  display: inline-block;
  background: linear-gradient(90deg, #3E59B7, #3E59B7, #3E59B7, #FFA5CB, #FFA5CB, #FFA5CB, #8397FF, #FFA5CB, #FFA5CB, #FFA5CB);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientChange3 3s ease-in-out infinite;
}




.scroll-container {
  overflow-x: auto;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
  white-space: nowrap;
  display: flex;
  width: 100%;
  max-width: 600px;
  /* Adjust to the width of 2 cards */
  margin: 0 auto;
}

.scroll-container::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}


.animate-slide-up {
  animation: slide-up 0.5s ease-in-out forwards;
}

.animationhidden {
  display: none;
}

@keyframes lineMotion {
  0% {
    stroke-dasharray: 12;
    stroke-dashoffset: 2;
  }

  5% {
    stroke-dasharray: 8 10;
  }

  25% {
    stroke-dasharray: 2 10;
  }

  100% {
    stroke-dasharray: 12;
    stroke-dashoffset: 2000;
  }
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out-up {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes fade-out-down {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

.animate-fade-in-up {
  animation: fade-in-up 0.5s forwards;
}

.animate-fade-out-up {
  animation: fade-out-up 0.5s forwards;
}

.animate-fade-out-down {
  animation: fade-out-down 0.5s forwards;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.custom-width {
  width: 400px;
}

.custom-pad {
  padding: 6px;
}


.random {
  width: 450px;
  margin-top: -50px;
  margin-left: 550px;
}

.custom-phone-input2 {
  /* background-color: #FFFFFF; */
  width: 100% !important;
  /* You can adjust the width as needed */

}

@keyframes fadeInFromBottom {
  from {
    opacity: 0;
    transform: translateY(40px) scale(0.65);
    visibility: hidden;
    /* Start hidden */
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(0.65);
    visibility: visible;
    /* End visible */
  }
}

@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-40px) scale(0.65);
    visibility: hidden;
    /* Start hidden */
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(0.65);
    visibility: visible;
    /* End visible */
  }
}


.fadeInFromBottom {
  animation: fadeInFromBottom ease-out 400ms forwards;
  /* Use forwards to maintain the end state */
}

.fadeInFromTop {
  animation: fadeInFromTop ease-out 400ms forwards;
  /* Use forwards to maintain the end state */
}

.fadeInDelay1 {
  animation-delay: 500ms;
  /* Delay for the first image */
}

.fadeInDelay2 {
  animation-delay: 1000ms;
  /* Delay for the second image */
}

.fadeInDelay3 {
  animation-delay: 1500ms;
  /* Delay for the third image */
}

.fadeInDelay4 {
  animation-delay: 2000ms;
  /* Delay for the fourth image */
}

.fadeInDelay5 {
  animation-delay: 2500ms;
  /* Delay for the fifth image */
}

.fadeInDelay6 {
  animation-delay: 3000ms;
  /* Delay for the sixth image */
}

.fadeInDelay7 {
  animation-delay: 3500ms;
  /* Delay for the seventh image */
}

.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
}

.carousel-image {
  width: 100%;
  height: fit-content;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
}

.carousel-button:first-of-type {
  left: 10px;
}

.carousel-button:last-of-type {
  right: 10px;
}