.thankYouContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
    height: 100vh;
    text-align: center;
    margin: 0;
    box-sizing: border-box;
    flex-direction: row;
    background: linear-gradient(180deg, #fff 50.88%, #e8f0ff 100%);
    position: relative;
    overflow: hidden;
  }
  
  .boxImage {
    width: 200px;
    height: 200px;
    position: absolute;
    bottom: 0;
    left: -25px;
    margin: 0;
    margin-bottom: 0;
  }
  
  .messageContainer,
  .demoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
  }
  
  .demoContainer {
    color: #1e38af;
    margin-right: 80px;
  }
  
  .thankYouImage {
    width: 80px;
    height: 80px;

  }
  
  .text {
    color: #3e59bf;
    font-size: 3em;
    font-weight: 700;
    margin: 10px 0;
  }
  
  .desc {
    color: #888888;
    font-size: 1.2em;
    font-weight: 400;
  }
  
  .demoText {
    color: #1e38af;
    margin-bottom: 20px;
    font-weight: 600;
  }
  
  .mockUpWrapper {
    position: relative;
    display: inline-block;
  }
  
  .demoImage {
    max-width: 80%;
    height: auto;
  }
  
  .demoVideo {
    position: absolute;
    top: 10px;
    right: 67px;
    height: 63%;
  }
  
  @media (max-width: 1920px) {
    .thankYouContainer {
      padding: 20px;
    }
  
    .messageContainer,
    .demoContainer {
      margin: 10px 0;
    }
  
    .demoVideo {
      top: 13px;
      right: 84px;
      height: 63%;
    }
  
    .boxImage {
      width: 180px;
      height: 180px;
    }
  
    .text {
      font-size: 3em;
    }
  
    .desc {
      font-size: 1.2em;
    }
  
    .demoText {
      font-size: 1.2em;
    }
  }
  
  @media (max-width: 1440px) {
    .thankYouContainer {
      padding: 20px;
    }
  
    .messageContainer,
    .demoContainer {
      margin: 10px 0;
    }
  
    .demoVideo {
      top: 13px;
      right: 82px;
      height: 63%;
    }
  
    .boxImage {
      width: 180px;
      height: 180px;
    }
  
    .text {
      font-size: 3em;
    }
  
    .desc {
      font-size: 1.2em;
    }
  
    .demoText {
      font-size: 1.2em;
    }
  }
  
  @media (max-width: 1280px) {
    .thankYouContainer {
      padding: 20px;
    }
  
    .messageContainer,
    .demoContainer {
      margin: 10px 0;
    }
  
    .demoVideo {
      top: 11px;
      right: 72px;
      height: 63.1%;
    }
  
    .boxImage {
      width: 180px;
      height: 180px;
    }
  
    .text {
      font-size: 3em;
    }
  
    .desc {
      font-size: 1.2em;
    }
  
    .demoText {
      font-size: 1.2em;
    }
  }
  
  @media (max-width: 1024px) {
    .thankYouContainer {
      padding: 20px;
    }
  
    .messageContainer,
    .demoContainer {
      margin: 10px 0;
    }
  
    .demoVideo {
      top: 9px;
      right: 58px;
      height: 62.8%;
    }
  
    .boxImage {
      width: 180px;
      height: 180px;
    }
  
    .text {
      font-size: 3em;
    }
  
    .desc {
      font-size: 1.2em;
    }
  
    .demoText {
      font-size: 1.2em;
    }
  }
  
  @media (max-width: 768px) {
    .thankYouContainer {
      padding: 20px;
    }
  
    .messageContainer,
    .demoContainer {
      margin: 10px 0;
    }
  
    .demoVideo {
      top: 6px;
      right: 42px;
      height: 62.8%;
    }
  
    .boxImage {
      width: 160px;
      height: 160px;
    }
  
    .text {
      font-size: 2em;
    }
  
    .desc {
      font-size: 1em;
    }
  
    .demoText {
      font-size: 1.2em;
    }
  }
  
  @media (max-width: 480px) {
    .thankYouContainer {
      flex-direction: column;
      padding: 20px;
    }
  
    .messageContainer,
    .demoContainer {
      margin: 10px 0;
    }
  
    .messageContainer {
      margin-top: 40px;
    }
  
    .demoVideo {
      top: 7px;
      right: 44px;
      height: 62.8%;
    }
  
    .boxImage {
      display: none;
    }
  
    .text {
      font-size: 2em;
    }
  
    .desc {
      font-size: 1em;
    }
  
    .demoText {
      font-size: 1.2em;
    }
  }
  
  @media (max-width: 395px) {
    .thankYouContainer {
      flex-direction: column;
      padding: 20px;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      box-sizing: border-box;
    }
  
    .messageContainer,
    .demoContainer {
      margin: 10px 0;
    }
  
    .messageContainer {
      margin-top: 120px;
    }
  
    .demoContainer {
      margin-bottom: 200px;
    }
  
    .demoVideo {
      position: absolute;
      top: 6px;
      right: 38px;
      height: 62.8%;
    }
  
    .boxImage {
      display: none;
    }
  
    .text {
      font-size: 2em;
    }
  
    .desc {
      font-size: 1em;
    }
  
    .demoText {
      font-size: 1.2em;
    }
  }
  
  @media (max-width: 380px) {
    body {
      overflow: none;
    }
  
    .thankYouContainer {
      flex-direction: column;
      padding: 20px;
    }
  
    .messageContainer,
    .demoContainer {
      margin: 10px 0;
    }
  
    .messageContainer {
      margin-top: 40px;
    }
  
    .demoVideo {
      top: 6px;
      right: 38px;
      height: 62.8%;
    }
  
    .boxImage {
      display: none;
    }
  
    .text {
      font-size: 2em;
    }
  
    .desc {
      font-size: 1em;
    }
  
    .demoText {
      font-size: 1.2em;
    }
  }
  
  @media (max-width: 320px) {
    body {
      overflow: none;
    }
  
    .thankYouContainer {
      flex-direction: column;
      padding: 10px;
    }
  
    .messageContainer,
    .demoContainer {
      margin: 10px 0;
    }
  
    .messageContainer {
      margin-top: 40px;
    }
  
    .demoVideo {
      top: 5px;
      right: 35.5px;
      height: 62.5%;
    }
  
    .boxImage {
      display: none;
    }
  
    .text {
      font-size: 2em;
    }
  
    .desc {
      font-size: 1em;
    }
  
    .demoText {
      font-size: 1.2em;
    }
  }
  