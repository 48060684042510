.contactSection {
  display: flex;
  justify-content: space-between;
  padding: 40px 20px;
  background: linear-gradient(69deg, #3E59B7 0%, #1B2751 100%);
  border-radius: 20px;
  margin-bottom: 40px;
  color: white;
  margin: 40px;
  align-items: center;
  position: relative;
}


.linksContainer {

  justify-content: space-between;

    display: flex;
    gap: 6.5rem; /* Adjust the value as needed */
  
}


.link {
  color: #ffffff;
  text-decoration: none;
  font-size: 12px;
  font-family: Montserrat;
  text-decoration: underline;

}

.link:hover {
  color: #c62b6d;
  text-decoration: underline;

}


.custom-phone-input1 {
  /* background-color: rgba(255, 255, 255, 0.2); */
  width: 100% !important;
  /* You can adjust the width as needed */

}

.content {
  flex: 1;
  padding-left: 80px;
  z-index: 20;
  font-family: Montserrat;
}

.heading {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: -10px;
  text-align: left;

}

.heading2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: left;
  margin-top: 10px;

}


.description {
  font-size: 1.2rem;
  color: white;
  margin-bottom: 8px;
  font-family: Montserrat;

  /* Add gradient background */
  background: linear-gradient(to right, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0) 50%);
  border-radius: 10px;
  /* opacity: 15%; */
  padding: 4px;
  /* Adjust as needed for spacing around the text */

  z-index: -1;
}

.poweredBy {
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo {
  width: 150px;
}

.form::before {
  content: "";
  position: absolute;
  width: 350px;
  height: 350px;
  border-radius: 100%;
  background: linear-gradient(90deg,
      rgba(77, 105, 233, 1) 0%,
      rgba(77, 105, 233, 0) 90%);
  left: 20px;
  top: 40px;
  /* z-index: -1; */
}

.form {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

}

.flagIcon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(10%);
  width: 20px;
  height: 20px;

}

.countryCode {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(10%);
  font-size: 14px;

}

.input-container {
  position: relative;
  width: 100%;
  max-width: 300px;

}

.input {
  width: 100%;
  padding: 4px;
  border-radius: 3px;
  border-color: rgb(202, 202, 202);
  /* Replace with your desired color */
  border-width: 0.75px;
  /* Ensure the border width is set */
  border-style: solid;
  /* Ensure the border style is set */

  background-color: rgba(255, 255, 255, 0.2);
  font-weight: 470;
  font-family: inherit;
  color: white;
  position: relative;
}

.contactInput {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  font-weight: 500;
  font-family: inherit;
  color: white;
  position: relative;
  padding-left: 80px;
}

placeholder {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 1rem;
  color: white;
  pointer-events: none;
  transition: 0.2s;
  font-family: Montserrat;
}

.input:focus+.placeholder,
.input:not(:placeholder-shown)+.placeholder {
  top: -10px;
  left: 10px;
  font-size: 0.8rem;
  color: white;
  background: transparent;
  font-family: Montserrat;
}

.button {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  background-color: white;
  color: #23389D;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  font-family: Montserrat;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkboxLabel {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;
}

.checkbox {
  margin-right: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #ffffff;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  position: relative;
}

.checkbox:checked::before {
  content: '\2713';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #ffffff;
}

.checkboxText {
  flex: 1;
  font-size: 10px;
  font-family: Montserrat ;
}

.checkboxLabel {
  width: 100%;
  padding-left: 0;
  justify-content: flex-start;
}

.button:hover {
  background-color: #C62B6D;
  color: white;
}

.button:disabled {
  background-color: rgb(203, 198, 198);
  color: rgb(119, 118, 160);
}
.button:disabled:hover {
  background-color: rgb(203, 198, 198);
  color: rgb(119, 118, 160);

}
.button:disabled:hover .arrowIcon {
  transform: rotate(0deg);
}

.arrow {
  margin-left: 8px;
  /* Adjust spacing as needed */
}

.arrowIcon {
  width: 24px;
  height: 20px;
  transition: transform 0.3s;
}

/* Hover effect for the SVG arrow */
.button:hover .arrowIcon {
  transform: rotate(-30deg);
}

.button:disabled .arrowIcon {
  transform: rotate(0deg);
}

.input::placeholder {
  color: white;
}

.error {
  color: rgb(239, 68, 68);
  position: relative;
  display: inline-block;
  width: 300px;
  font-size: 14px;
}

@media screen and (max-width: 868px) {
  .section {
    margin: 20px;
    padding: 0px;
  }

  .heading {
    font-size: 1.5rem;
    text-align: center;
  }

  .heading2 {
    font-size: 1rem;
    font-weight: 450;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 10px;
  }

  .description {
    text-align: center;
    font-family: Montserrat;
  }

  .poweredBy {
    margin-top: 20px;
    text-align: center;
    justify-content: center;
  }

  .contactSection {
    flex-direction: column;
    padding: 20px;
    margin: 20px;
  }

  .content {
    padding-left: 0;
    margin-bottom: 20px;
    font-family: Montserrat;
  }

  .form::before {
    display: none;
  }

  .checkboxLabel {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    line-height: 1.4;
    text-align: left;
    max-width: 300px;
  }

  .checkbox {
    margin-right: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #ffffff;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    position: relative;
  }

  .checkbox:checked::before {
    content: '\2713';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #ffffff;
  }

  .checkboxText {
    flex: 1;
    font-size: 10px;
    text-justify: auto;
    font-family: Montserrat;
  }

  .checkboxLabel {
    width: 100%;
    padding-left: 0;
    justify-content: flex-start;
  }


  .button {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    background-color: white;
    color: #23389D;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
    font-family: Montserrat;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .contactSection {
    flex-direction: column;
    text-align: left;
    padding: 35px 0;
    margin: 10px;
  }

  .input {
    width: 80%;
    padding: 4px;
    border-radius: 3px;
    border-color: rgb(202, 202, 202);

    /* Replace with your desired color */
    border-width: 0.75px;
    /* Ensure the border width is set */
    border-style: solid;
    /* Ensure the border style is set */

  }

  .contactInput {
    width: 57.2%;
  }

  .flagIcon {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(10%);
    width: 20px;
    height: 20px;
  }

  .countryCode {
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translateY(10%);
    font-size: 14px;
  }

  /* .heading {
      font-size: 1.5rem;
    } */

  .description {
    font-size: 1rem;
  }

  .content {
    padding-left: 0;
    margin-bottom: 20px;
    font-family: Montserrat;
  }




  .button {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    background-color: white;
    color: #23389D;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
    font-family: Montserrat;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button:hover {
    background-color: #C62B6D;
    color: white;
  }

  .arrow {
    margin-left: 8px;
    /* Adjust spacing as needed */
  }

  .arrowIcon {
    width: 24px;
    height: 20px;
    transition: transform 0.3s;
  }

  /* Hover effect for the SVG arrow */
  .button:hover .arrowIcon {
    transform: rotate(-30deg);
  }

  .heading {
    font-size: 1.5rem;
    font-weight: 800;
    margin-bottom: 10px;
  }

  .form {
    gap: 15px;
  }

  .form::before {
    display: none;
  }
}

@media screen and (min-width:870px) {
  .contactSection {
    display: flex;
    justify-content: space-between;
    padding: 40px 20px;
    background: linear-gradient(69deg, #3E59B7 0%, #1B2751 100%);
    border-radius: 20px;
    margin-bottom: 40px;
    color: white;
    margin: 40px;
    align-items: center;
    position: relative;
  }

  .content {
    flex: 1;
    padding-left: 80px;
    z-index: 20;
    font-family: Montserrat;
  }

  .heading {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: -10px;
    text-align: left;

  }

  .heading2 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: left;
    margin-top: 10px;

  }


  .description {
    font-size: 1.2rem;
    color: white;
    margin-bottom: 8px;
    font-family: Montserrat;

    /* Add gradient background */
    background: linear-gradient(to right, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0) 50%);
    border-radius: 10px;
    /* opacity: 15%; */
    padding: 4px;
    /* Adjust as needed for spacing around the text */

    z-index: -1;
  }

  .poweredBy {
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .logo {
    width: 150px;
  }

  .form::before {
    content: "";
    position: absolute;
    width: 350px;
    height: 350px;
    border-radius: 100%;
    background: linear-gradient(90deg,
        rgba(77, 105, 233, 1) 0%,
        rgba(77, 105, 233, 0) 90%);
    left: 20px;
    top: 40px;
    /* z-index: -1; */
  }

  .form {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

  }

  .flagIcon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(10%);
    width: 20px;
    height: 20px;

  }

  .countryCode {
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translateY(10%);
    font-size: 14px;

  }

  .input-container {
    position: relative;
    width: 100%;
    max-width: 300px;
  }

  .input {
    width: 100%;
    padding: 4px;
    border-radius: 3px;
    border-color: rgb(202, 202, 202);
    /* Replace with your desired color */
    border-width: 0.75px;
    /* Ensure the border width is set */
    border-style: solid;
    /* Ensure the border style is set */

    background-color: rgba(255, 255, 255, 0.2);
    font-weight: 470;
    font-family: inherit;
    color: white;
    position: relative;
  }

  .contactInput {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: rgba(255, 255, 255, 0.2);
    font-weight: 500;
    font-family: inherit;
    color: white;
    position: relative;
    padding-left: 80px;
  }

  placeholder {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 1rem;
    color: white;
    pointer-events: none;
    transition: 0.2s;
    font-family: Montserrat;
    border: none;
  }

  .input:focus+.placeholder,
  .input:not(:placeholder-shown)+.placeholder {
    top: -10px;
    left: 10px;
    font-size: 0.8rem;
    color: white;
    background: transparent;
    font-family: Montserrat;
  }

  .button {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    background-color: white;
    color: #23389D;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
    font-family: Montserrat;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button:hover {
    background-color: #C62B6D;
    color: white;
  }

  .arrow {
    margin-left: 8px;
    /* Adjust spacing as needed */
  }

  .arrowIcon {
    width: 24px;
    height: 20px;
    transition: transform 0.3s;
  }

  /* Hover effect for the SVG arrow */
  .button:hover .arrowIcon {
    transform: rotate(-30deg);
  }

  .input::placeholder {
    color: white;
  }

  .error {
    color: rgb(239, 68, 68);
    position: relative;
    display: inline-block;
    width: 300px;
    font-size: 14px;

  }

  .checkboxLabel {
    display: flex;
    align-items: flex-start;
    font-size: 6px;
    line-height: 1.4;
    text-align: left;
    max-width: 300px;
  }

  .checkbox {
    margin-right: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #ffffff;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    position: relative;
  }

  .checkbox:checked::before {
    content: '\2713';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #ffffff;
  }

  .checkboxText {
    flex: 1;
    font-size: 9px;
    font-family: Montserrat ;
  }

  .checkboxLabel {
    width: 100%;
    padding-left: 0;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 480px) {
  .section {
    margin: 20px;
    padding: 0px;
  }

  .heading {
    font-size: 1.8rem;
    /* font-weight:500; */
    text-align: center;
  }

  .heading2 {
    font-size: 1.06rem;
    font-weight: 300;
    margin-bottom: 20px;
    text-align: center;
    margin-top: 10px;
  }

  .disclaimerContainer {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .description {
    text-align: center;
    font-size: 1.06rem;
    font-weight: 600;
    text-align: center;
    font-family: Montserrat;
  }

  .bridge {
    font-size: 1.05rem;
    font-weight: 400;
    text-align: center;
    font-family: Montserrat;
  }

  .poweredBy {
    margin-top: 20px;
    text-align: center;
    justify-content: center;
  }

  .contactSection {
    flex-direction: column;
    padding: 20px;
    margin: 20px;
  }

  .content {
    padding-left: 0;
    margin-bottom: 20px;
    font-family: Montserrat;
  }

  .form::before {
    display: none;
  }

  placeholder {
    position: absolute;
    top: 10px;
    left: 0px;
    font-size: 1rem;
    color: white;
    pointer-events: none;
    transition: 0.2s;
    font-family: Montserrat;
  }

  .input-container {
    position: relative;
    width: 100%;
    max-width: 300px;
  }

  .input {
    width: 100%;
    padding: 4px;
    border-radius: 3px;
    border-color: rgb(202, 202, 202);
    /* Replace with your desired color */
    border-width: 0.75px;
    /* Ensure the border width is set */
    border-style: solid;
    /* Ensure the border style is set */

    background-color: rgba(255, 255, 255, 0.2);
    font-weight: 470;
    font-family: inherit;
    color: white;
    position: relative;
  }

  .link{
    font-size: 11px;
  }

  .checkboxLabel {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    line-height: 1.4;
    text-align: left;
  }

  .checkbox {
    margin-right: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #ffffff;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    position: relative;
  }

  .checkbox:checked::before {
    content: '\2713';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #ffffff;
  }

  .checkboxText {
    flex: 1;
    font-size: 10px;
    font-family: Montserrat ;
  }

  .checkboxLabel {
    width: 100%;
    padding-left: 0;
    justify-content: flex-start;
  }

  .button {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    background-color: white;
    color: #23389D;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
    font-family: Montserrat;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button:hover {
    background-color: #C62B6D;
    color: white;
  }

  .arrow {
    margin-left: 8px;
    /* Adjust spacing as needed */
  }

  .arrowIcon {
    width: 24px;
    height: 20px;
    transition: transform 0.3s;
  }

  /* Hover effect for the SVG arrow */
  .button:hover .arrowIcon {
    transform: rotate(-30deg);
  }

  .contactInput {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: rgba(255, 255, 255, 0.2);
    font-weight: 500;
    font-family: inherit;
    color: white;
    position: relative;
    padding-left: 80px;
  }

  .input:focus+.placeholder,
  .input:not(:placeholder-shown)+.placeholder {
    top: -10px;
    left: 0px;
    font-size: 0.8rem;
    color: white;
    background: transparent;
    font-family: Montserrat;
  }
}

@media screen and (max-width:400px) {
  .link{
    font-size: 10px;
  }
  
}


@keyframes gradientChange4 {
  0% {
    background-position: 100% 50%;
    -webkit-text-fill-color: transparent;
  }

  50% {
    background-position: 50% 50%;
    -webkit-text-fill-color: transparent;
  }

  100% {
    background-position: 0% 50%;
    -webkit-text-fill-color: transparent;
  }
}

.gradient-text4 {
  display: inline-block;
  background: linear-gradient(90deg, #ffffff, #ffffff, #ffffff, #FFA5CB, #FFA5CB, #FFA5CB, #8397FF, #FFA5CB, #FFA5CB, #ffffff, #ffffff, #ffffff);
  background-size: 300% 300%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientChange4 5s ease-in-out infinite;
  font-family: Montserrat;
}